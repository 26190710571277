<div *ngIf="isNotInAppMain()" class="d-flex flex-column wrapper" id="kt_wrapper">
    <div id="kt_header"
        class="header header-{{currentTheme.baseSettings.header.desktopFixedHeader ? 'fixed':'static'}} head--skin-{{currentTheme.baseSettings.header.headerSkin}}">
        <div id="kt_brand" class="brand flex-column-auto" kt-hidden-height="65">
            <div class="brand-logo">
                <a href="">
                    <img id="streamlinea-logo" [src]="defaultLogo" alt="Streamlinea Logo" height="45" style="border-left: 1px solid #E2E5EC;" />
                    <img id="client-logo" *ngIf="appSession.tenant && appSession.tenant.logoId"
                         [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId"
                         alt="Client Logo" height="45" />
                </a>
            </div>
        </div>
    </div>
</div>

<div [@routerTransition]>

    <div class="m-subheader">
        <div class="d-flex align-items-center">

            <div *ngIf="isDetailPage === false && masterPageLabelKey !== undefined" class="col-xs-12">
                <h3 class="m-subheader__title m-subheader__title--separator">
                    <span id="master-page-name" (click)="goToDynamicFrameworkMasterPage(masterPageLabelKey)" [title]="'Go To ' + l(masterPageLabelKey)">{{l(masterPageLabelKey)}}</span>
                </h3>
            </div>
            <div *ngIf="isDetailPage === false" class="col-xs-12">
                <h3 class="m-subheader__title m-subheader__title--separator">
                    <span>{{l(getPageLabelKey())}}</span>
                </h3>
            </div>
             <div *ngIf="isDetailPage === false" class="mr-auto col-xs-12">
                <h3 class="m-subheader__title">
                    <span id="dynamic-form-name">{{l(getParentPageName())}}</span>
                </h3>
            </div>

            <div *ngIf="isDetailPage" class="col-xs-12">
                <h3 class="m-subheader__title m-subheader__title--separator">
                    <span id="master-page-name" (click)="goToMasterPage(passedMasterPageId)" [title]="'Go To ' + l(masterPageLabelKey)">{{l(masterPageLabelKey)}}</span>
                </h3>
            </div>
             <div *ngIf="isDetailPage" class="mr-auto col-xs-12">
                <h3 class="m-subheader__title">
                    <span>{{l(getPageLabelKey())}}</span>
                </h3>
            </div>

            <div *ngIf="(permission.isGranted('Pages.Administration.Host.Settings') && clientAndErpFilterEnabled(convertedPageName))" class="col-xs-12 text-right">
                <client-and-erp-filter (getSelectedClientAndErpEmitter)="getSelectedClientAndErp($event)">
                </client-and-erp-filter>
            </div>
        </div>
    </div>

    <div id="streamlinea" class="m-content">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">

                <!-- NOTE: for Dynamic Pages -->
                <form *ngIf="isDynamicPages(convertedPageName) && formComponents.length > 0" name="dynamic-form" #dynamicForm="ngForm" novalidate autocomplete="off" (ngSubmit)="saveDynamicForm()">
                    <div class="modal-row-margin">
                        <div *ngFor="let formComponent of formComponents; let i=index">
                            <div>
                                <br *ngIf="i > 0" />
                                <h4 *ngIf="formComponent.labelIsHidden === false" [innerHtml]="l(formComponent.dynamicFormNameKey)"></h4>
                                <dynamic-form #dynamicForm
                                              [formStructureDataSourceName]="formComponent.formStructureDataSourceName"
                                              [formDataDataSourceName]="formComponent.formDataDataSourceName"
                                              [useParentComponentData]="formComponent.useParentComponentData"
                                              [parentItem]="getFormPageData(formComponent.useParentComponentData, formComponent.formStructureDataSourceName)"
                                              [retrieveDataBy]="retrieveDataBy"
                                              [useDefaultActionButton]="false"
                                              [formStructure]="formStructure"
                                              [pageName]="pageName"
                                              [fieldLabelWidth]="fieldLabelWidth"
                                              [fieldControlWidth]="fieldControlWidth"
                                              [groupFieldSetWidth]="groupFieldSetWidth"
                                              [groupFieldSetGap]="groupFieldSetGap"
                                              [isHidden]="isFormHidden(formComponent.formStructureDataSourceName)"
                                              [showBorder]="showBorder"
                                              [showGuide]="showGuide">
                                </dynamic-form>
                            </div>
                        </div>
                    </div>
                </form>

                <ng-template #customBillNumber let-colField="colField" let-dataItem="dataItem">
                    <div [innerHtml]="'<pre>'+dataItem[colField]+'</pre>'"></div>
                </ng-template>

                <ng-template #customPayCheckBox let-dataItem="dataItem">
                    <div class="k-form-field text-center">
                        <input type="checkbox" class="k-checkbox" id="{{dataItem.id}}" [(ngModel)]="dataItem.isSelected" (change)="getSelectedItemIds()" />
                        <label class="k-checkbox-label" for="{{dataItem.id}}"></label>
                    </div>
                </ng-template>

                <ng-template #customMenuIcon let-colField="colField" let-dataItem="dataItem">
                    <div><i [class]="dataItem['menuIcon']"></i></div>
                </ng-template>

                <!-- NOTE: for Dynamic Pages -->
                <div *ngIf="isDynamicPages(convertedPageName)">
                    <div *ngFor="let gridComponent of gridComponents; let gridComponentIndex=index">
                        <div>
                            <dynamic-grid #dynamicGrid
                                          *ngIf="pageName === convertedPageName"
                                          [gridStructureDataSourceName]="gridComponent.gridStructureDataSourceName"
                                          [gridDataDataSourceName]="gridComponent.gridDataDataSourceName"
                                          [useParentComponentData]="gridComponent.useParentComponentData"
                                          [parentItem]="getGridPageData(gridComponent.useParentComponentData, gridComponent.gridStructureDataSourceName)"
                                          [isHidden]="isGridHidden(gridComponent.gridStructureDataSourceName)"
                                          [gridComponent]="gridComponent"
                                          [gridComponentIndex]="getGridComponentIndex(gridComponentIndex, gridComponent.gridStructureDataSourceName)"
                                          [gridName]="gridComponent.dynamicGridNameKey"
                                          [pageName]="pageName"
                                          [catalogId]="catalogId"
                                          [erpId]="erpId"
                                          [clientId]="clientId"
                                          [retrieveDataBy]="retrieveDataBy"
                                          [customTemplates]="customTemplates"
                                          [gridStructure]="gridStructure"
                                          [dataSourceItems]="dataSourceItems"
                                          [hasDependency]="hasDependency"
                                          (cellClickEmitter)="cellClick($event)"
                                          (getDataEmitter)="getData($event)"
                                          (parentPageNameEmitter)="parentPageName = $event">
                            </dynamic-grid>
                        </div>
                    </div>
                </div>

                <div *ngIf="false">
                    <br />
                    <br />
                    <dynamic-treelist [treelistStructureDataSourceName]="'GridStructureDynamicFormDesignMaintenance'"
                                      [treelistDataDataSourceName]="'GridDataDynamicFormDesignMaintenance'"
                                      [treelistName]="gridName">
                    </dynamic-treelist>
                </div>

                <dynamic-component *ngIf="isStreamlineaPages(convertedPageName) && !isOldDynamicComponent() && componentVisible"
                                   [masterPageId]="masterPageId"
                                   [masterPageLabelKey]="masterPageLabelKey"
                                   [isDetailPage]="isDetailPage"
                                   [formIdentifier]="formIdentifier"
                                   [pageId]="pageId"
                                   [gridStructures]="gridStructures"
                                   [formStructures]="formStructures"
                                   [gridsData]="gridsData"
                                   [formsData]="formsData"
                                   [pageName]="pageName"
                                   [fieldLabelWidth]="fieldLabelWidth"
                                   [fieldControlWidth]="fieldControlWidth"
                                   [groupFieldSetWidth]="groupFieldSetWidth"
                                   [groupFieldSetGap]="groupFieldSetGap"
                                   [showBorder]="showBorder"
                                   [showGuide]="showGuide"
                                   (getClientIdAndErpIdEmitter)="getClientIdAndErpIdEmitter($event)"
                                   (getDynamicPageLabelKey)="setDynamicPageLabelKey($event)">
                </dynamic-component>

                <dynamic-component-old *ngIf="isOldDynamicComponent() && componentVisible"
                                       [pageId]="pageId"
                                       [gridStructures]="gridStructures"
                                       [formStructures]="formStructures"
                                       [gridsData]="gridsData"
                                       [formsData]="formsData"
                                       [pageName]="pageName"
                                       [fieldLabelWidth]="fieldLabelWidth"
                                       [fieldControlWidth]="fieldControlWidth"
                                       [groupFieldSetWidth]="groupFieldSetWidth"
                                       [groupFieldSetGap]="groupFieldSetGap"
                                       [showBorder]="showBorder"
                                       [showGuide]="showGuide">
                </dynamic-component-old>

                <div *ngIf="isDynamicPages(convertedPageName)" class="col-md-12 action-buttons text-right">
                    <button (click)="confirmPublishChanges()" [buttonBusy]="buttonDisabled" [busyText]="l('PublishingChangesWithThreeDot')" [disabled]="buttonDisabled || checkGridAndForms()" class="btn btn-primary" accesskey="p">{{l("PublishChanges")}}</button>
                    <button *ngIf="convertedPageName === 'DynamicPageDesignMaintenance'" (click)="goToPageLayoutDefinitions()" class="btn btn-secondary" accesskey="l">{{l("GoToPageLayoutDefinitions")}}</button>
                    <button *ngIf="convertedPageName === 'DynamicPageDesignMaintenance'" (click)="goToPage()" class="btn btn-secondary" accesskey="a">{{l("GoToPage")}}</button>
                </div>

                <unsaved-changes [dialogOpened]="openUnsavedChanges" [savingChangesFromDialog]="savingChangesFromDialog" [usePublish]="usePublish" [disableSave]="disableSave" (cancel)="closeUnsavedChangesDialog()" (discard)="discardChanges()" (save)="publishChanges(true)"></unsaved-changes>

            </div>
        </div>
    </div>
</div>