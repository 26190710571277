<kendo-dialog *ngIf="dialogOpened">
    <div class="text-center kendo-dialog-padding">
        <div class="swal2-icon swal2-warning swal2-animate-warning-icon alert-dialog-display">
            <span class="swal2-icon-content">!</span>
        </div>
        <h5>{{usePublish ? l("UnpublishedChanges") : l("UnsavedChanges")}}</h5>
        <br />
        <div *ngIf="!dialogOpenedBySync" class="pop-up-detail" [innerHTML]='disableSave ? l("YouHaveMissingRequiredFields") : usePublish ? l("YouHaveUnpublishedChanges") : l("YouHaveUnsavedChanges")'></div>
        <div *ngIf="dialogOpenedBySync" class="pop-up-detail" [innerHTML]='l("UserChangeYouHaveUnsavedChanges")'></div>
        <br />
        <div>
            <button type="button" class="btn btn-secondary kendo-dialog-button-padding" (click)="closeDialog()">{{l("Cancel")}}</button>
            <button type="button" class="btn btn-secondary kendo-dialog-button-padding" (click)="discardChanges()">{{l("Discard")}}</button>
            <button type="button" class="btn btn-primary kendo-dialog-button-padding" (click)="saveChanges()" [buttonBusy]="savingChangesFromDialog" [busyText]="l('SavingWithThreeDot')" [disabled]="disableSave">{{usePublish ? l("PublishChanges") : l("Save")}}</button>
        </div>
    </div>
</kendo-dialog>