import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Injector, Input, QueryList, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { merge as _merge, uniqBy as _uniqBy, uniq as _uniq } from 'lodash-es';
import { DateTime } from 'luxon';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomTemplate } from '@app/shared/common/dynamic-components/dynamic-grid/custom-template';
import { DynamicGridComponent } from '@app/shared/common/dynamic-components/dynamic-grid/dynamic-grid.component';
import { DynamicFormComponent } from '@app/shared/common/dynamic-components/dynamic-form/dynamic-form.component';
import { catchError, finalize } from 'rxjs/operators';
import { DynamicDataServiceProxy, RetrieveDynamicDataRequest, SaveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { Observable, of, Subject } from 'rxjs';
import { DataSourceItems } from '@app/shared/common/dynamic-components/dynamic-grid/data-source-items';
import { clone as _clone } from 'lodash-es';
import { ClientAndErpFilterComponent } from '@app/shared/common/client-and-erp-filter/client-and-erp-filter.component';
import { DynamicComponentComponent } from '../dynamic-component/dynamic-component.component';
import { ComponentCanDeactivate } from '@app/shared/common/unsaved-changes-guard/unsaved-changes-guard';
import { DynamicActionButtonComponent } from '../dynamic-action-button/dynamic-action-button.component';
import { AppConsts } from '@shared/AppConsts';

@Component({
    templateUrl: './dynamic-page.component.html',
    styleUrls: ['./dynamic-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicPageComponent extends AppComponentBase implements ComponentCanDeactivate {
    @ViewChildren('dynamicGrid') dynamicGridComponents: QueryList<DynamicGridComponent>;
    @ViewChildren('dynamicForm') dynamicFormComponents: QueryList<DynamicFormComponent>;
    @ViewChild(ClientAndErpFilterComponent) clientAndErpFilterComponent: ClientAndErpFilterComponent;
    @ViewChild(DynamicComponentComponent) dynamicComponentComponents: DynamicComponentComponent;

    @Input() nameKey: string = "";
    pageLabelKey: string;
    pageName: string;
    pageId: string;
    convertedPageName: string;
    gridName: string;
    gridStructureDataSourceName: string;
    gridDataDataSourceName: string;
    formStructureDataSourceName: string = "";
    formDataDataSourceName: string = "";
    gridStructure: any;
    formStructure: any;
    gridStructures: any;
    formStructures: any;
    gridsData: any;
    formsData: any;
    dataAsOf: DateTime;
    selectedItemIds: string[] = [];
    editedRowIndex: number;
    customTemplates: CustomTemplate[] = [];
    @ViewChild('customBillNumber') public customBillNumber: TemplateRef<any>;
    @ViewChild('customPayCheckBox') public customPayCheckBox: TemplateRef<any>;
    @ViewChild('customMenuIcon') public customMenuIcon: TemplateRef<any>;
    formGroup: FormGroup;
    pageData: any;
    gridComponents: any[] = [];
    formComponents: any[] = [];
    retrieveDataBy: string;
    parentPageId: any;
    parentPageName: string;
    catalogId: number = 0;
    erpId: number = 0;
    clientId: number = 0;
    fieldLabelWidth: number = 0;
    fieldControlWidth: number = 0;
    groupFieldSetWidth: number = 0;
    groupFieldSetGap: number = 0;
    showBorder: boolean = false;
    showGuide: boolean = false;
    buttonDisabled: boolean = false;
    dataSourceItems: DataSourceItems[] = [];
    isAdminPage: boolean;
    masterPageId: string = "";
    masterPageLabelKey: string = "";
    isDetailPage: boolean = false;
    formIdentifier: string = "";
    componentVisible: boolean = false;
    passedMasterPageId: string = "";
    openUnsavedChanges: boolean = false;
    savingChangesFromDialog: boolean = false;
    selectedResponseSubject = new Subject<boolean>();
    selectedResponseAction$ = this.selectedResponseSubject.asObservable();
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    appUrl = AppConsts.appBaseUrl;
    hasDependency = false;
    usePublish = false;
    disableSave = false;

    constructor(
        injector: Injector,
        private _activatedRoute: ActivatedRoute,
        private cd: ChangeDetectorRef,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy,
        private _router: Router
    ) {
        super(injector);
    }

    clientAndErpFilterEnabled(convertedPageName: string): boolean {
        // Clients and ERP Filter will be disabled for Streamlinea Admin pages containing a ClientId of 0 and an ErpId of 0.
        // This feature is used only in the Page Layout Definitions
        return this.clientAndErpService.showClientAndErpDataSimulation && this.isStreamlineaPages(convertedPageName) && this.clientId !== 0 && this.erpId !== 0;
    }

    getClientIdAndErpIdEmitter($event): void {
        this.clientAndErpService.showClientAndErpDataSimulation = ($event.clientId === 0 && $event.erpId === 0) || ($event.clientId === undefined && $event.erpId === undefined);
    }

    getSelectedClientAndErp($event): void {
        this.dynamicComponentComponents.refreshStructureToLoadData($event.selectedClientId, $event.selectedErpId);
    }

    redirectToPage(masterPageId: string, detailPageId: string = ""): void {
        this._router.onSameUrlNavigation = 'ignore';

        if (detailPageId === "") {
            this._router.navigate(['app/main/dynamic-page/' + masterPageId]);
        }
        else {
            this._router.navigate(['app/main/dynamic-detail-page/' + masterPageId, detailPageId]);
        }
    }

    navigateToPage(): void {
        if (this._activatedRoute.snapshot["_urlSegment"].segments[0].path !== 'app' && this._activatedRoute.snapshot["_urlSegment"].segments[1].path !== 'main') {
            if (this._activatedRoute.snapshot.params['pageId'] !== undefined) {
                this.usePublish = false;
                this.masterPageId = this._activatedRoute.snapshot["_urlSegment"].segments[1]?.path;
                const detailPageIdOrFormIdentifier = this._activatedRoute.snapshot["_urlSegment"].segments[2]?.path;
                this.masterPageLabelKey = this._activatedRoute.snapshot["_urlSegment"].segments[3]?.path;
                this.passedMasterPageId = this._activatedRoute.snapshot["_urlSegment"].segments[4]?.path;
                this.convertedPageName = this.convertKebabCaseToPascalCase(this._activatedRoute.snapshot.params['pageId']);

                this.isDetailPage = detailPageIdOrFormIdentifier !== undefined && !this.isAdminPage;
                this.retrieveDataBy = detailPageIdOrFormIdentifier;

                this.pageId = this.retrieveDataBy;
                this.formIdentifier = detailPageIdOrFormIdentifier;

                if (this.convertedPageName !== this.pageName) {
                    this.pageName = this.convertedPageName;
                    this.reinitializeComponent();
                }
            }
        }
        else {
            this.isAdminPage = this._activatedRoute.snapshot["_urlSegment"].segments[2]?.path === "admin-page";
            this.masterPageId = this._activatedRoute.snapshot["_urlSegment"].segments[3]?.path;
            const detailPageIdOrFormIdentifier = this._activatedRoute.snapshot["_urlSegment"].segments[4]?.path;
            this.masterPageLabelKey = this._activatedRoute.snapshot["_urlSegment"].segments[5]?.path;
            this.passedMasterPageId = this._activatedRoute.snapshot["_urlSegment"].segments[6]?.path;

            this.isDetailPage = detailPageIdOrFormIdentifier !== undefined && !this.isAdminPage;
            this.retrieveDataBy = detailPageIdOrFormIdentifier;

            if (this.isAdminPage) {
                this.usePublish = true;
                if (this._activatedRoute.snapshot.params['masterPageId'] !== undefined) {
                    this.convertedPageName = this.convertKebabCaseToPascalCase(this._activatedRoute.snapshot.params['masterPageId']);
                    this.pageId = this.retrieveDataBy;

                    if (this.convertedPageName !== this.pageName) {
                        this.pageName = this.convertedPageName;
                        this.pageLabelKey = this.pageName;
                        this.retrieveStructure();
                    }
                }
            }
            else {
                this.usePublish = false;
                if (this._activatedRoute.snapshot.params['pageId'] !== undefined) {
                    this.convertedPageName = this.convertKebabCaseToPascalCase(this._activatedRoute.snapshot.params['pageId']);
                    this.pageId = this.retrieveDataBy;
                    this.formIdentifier = detailPageIdOrFormIdentifier;

                    if (this.convertedPageName !== this.pageName) {
                        this.pageName = this.convertedPageName;
                        this.reinitializeComponent();
                    }
                }
            }
        }
    }

    private reinitializeComponent() {
        this.componentVisible = false;
        this.cd.detectChanges();
        this.componentVisible = true;
        this.cd.detectChanges();
    }

    ngOnInit() {
        this.navigateToPage();

        this.subUnsavedChangesUpdate();
    }

    ngDoCheck() {
        this.navigateToPage();
    }

    isOldDynamicComponent(): boolean {
        return (this.convertedPageName === "ControlTypes" ||
            this.convertedPageName === "RushPayments" ||
            this.convertedPageName === "EntityMaintenance");
    }

    getPageLabelKey(): string {
        return this.pageLabelKey;
    }

    getParentPageName(): string {
        return this.parentPageName;
    }

    retrieveStructure(): void {
        this.gridIsLoading();

        if (this.convertedPageName === "DynamicGridDesignMaintenance" || this.convertedPageName === "DynamicFormDesignMaintenance" || this.convertedPageName === "DynamicPageDesignMaintenance" || this.convertedPageName === "DynamicTabDesignMaintenance") {
            this.getDynamicData();
        }
        else {
            this.parentPageName = "";
            this.getDynamicStructure();
            this.retrieveRequiredData();
        }
    }

    getDynamicStructure() {
        let parentId = "0";
        if (this.retrieveDataBy != undefined) {
            parentId = this.retrieveDataBy;
        }

        this._dynamicDataServiceProxy.retrieveDynamicStructure(this.pageName, parentId)
            .pipe(
                finalize(() => {
                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (result.data !== null && result.data.items !== undefined) {
                    result.data.items.forEach(item => {
                        item = this.formatItemDate(item);
                    });

                    //NOTE: For Dynamic Pages
                    if (this.isDynamicPages(this.convertedPageName)) {
                        this.gridComponents = result.data.items[0]?.attachedDynamicGridComponents;
                        this.formComponents = result.data.items[0]?.attachedDynamicFormComponents;
                    }

                    //NOTE: For Streamlinea Pages
                    if (result.data.items.length > 0) {
                        this.gridStructures = result.data.items[0]?.attachedDynamicGridStructures;
                        this.formStructures = result.data.items[0]?.attachedDynamicFormStructures;
                    }

                    if (this.formStructures !== undefined && this.formStructures.length > 0 && this.formStructures[0]?.componentSettings !== undefined) {
                        this.fieldLabelWidth = this.formStructures[0].componentSettings.fieldLabelWidth;
                        this.fieldControlWidth = this.formStructures[0].componentSettings.fieldControlWidth;
                        this.groupFieldSetWidth = this.formStructures[0].componentSettings.groupFieldSetWidth;
                        this.groupFieldSetGap = this.formStructures[0].componentSettings.groupFieldSetGap;
                        this.showBorder = this.formStructures[0].componentSettings.showBorder;
                        this.showGuide = this.formStructures[0].componentSettings.showGuide;
                    }
                }
            });
    }

    getFormPageData(useParentComponentData: boolean, formStructureDataSourceName: string) {
        if (useParentComponentData) {
            return this.formsData.filter(x => x.formName === formStructureDataSourceName)[0]?.items[0];
        }
    }

    getGridPageData(useParentComponentData: boolean, gridStructureDataSourceName: string) {
        if (useParentComponentData) {
            return this.gridsData.filter(x => x.gridName === gridStructureDataSourceName)[0]?.items;
        }
    }

    isGridHidden(gridStructureDataSourceName: string): boolean {
        if (gridStructureDataSourceName === "GridStructureAttachDetachDynamicTabComponent"
            && (this.gridsData[0].items.length > 0 || this.gridsData[1].items.length > 0 || this.gridsData[2].items.length > 0)) {
            return true;
        }
        else if ((gridStructureDataSourceName === "GridStructureAttachDetachDynamicGridComponent"
            || gridStructureDataSourceName === "GridStructureAttachDetachDynamicFormComponent"
            || gridStructureDataSourceName === "GridStructureAttachDetachDynamicActionButtonComponent")
            && this.gridsData[3].items.length > 0) {
            return true;
        }

        return false;
    }

    isFormHidden(formStructureDataSourceName: string): boolean {
        if (formStructureDataSourceName === "FormStructureDynamicPageDesignMaintenance" && this.gridsData[3].items.length === 0) {
            return true;
        }

        return false;
    }

    getGridComponentIndex(gridComponentIndex: number, gridStructureDataSourceName: string): number {
        if (gridStructureDataSourceName === "GridStructureAttachDetachDynamicTabComponent" && this.gridsData[3].items.length > 0) {
            gridComponentIndex = 0;
        }

        return gridComponentIndex;
    }

    getDynamicData() {
        let data = {};
        if (this.retrieveDataBy !== "") {
            data['id'] = this.retrieveDataBy;
        }

        if (this.gridStructure !== undefined) {
            //TODO: should come from URL
            data['id'] = this.gridStructure.componentSettings.gridId;
        }

        let request = new RetrieveDynamicDataRequest();
        request.dataSourceName = "PageData" + this.pageName;
        request.data = data;

        this._dynamicDataServiceProxy.retrieveDynamicData(request)
            .pipe(
                finalize(() => {
                })
            ).subscribe(result => {
                if (result.data !== null) {
                    this.parentPageId = result.data.pageSettings.parentId;
                    this.parentPageName = result.data.pageSettings.parentName;
                    this.catalogId = result.data.pageSettings.catalogId;
                    this.erpId = result.data.pageSettings.erpId;
                    this.clientId = result.data.pageSettings.clientId;
                    this.hasDependency = result.data.pageSettings.hasDependency;

                    this.gridsData = result.data.gridsData;
                    this.formsData = result.data.formsData;
                }

                this.getDynamicStructure();
                this.retrieveRequiredData();
            });
    }

    ngAfterContentInit() {
    }

    ngAfterContentChecked() {
    }

    ngAfterViewInit() {
        this.addCustomTemplates();
    }

    ngAfterViewChecked() {
    }

    ngOnDestroy() {
        this.unSubUnsavedChangesUpdate();
    }

    addCustomTemplates() {
        this.customTemplates.push(Object.assign(new CustomTemplate(), {
            templateName: this.customBillNumber,
            columnName: "billNumber",
            hasPermission: true
        }));

        this.customTemplates.push(Object.assign(new CustomTemplate(), {
            templateName: this.customPayCheckBox,
            columnName: "isSelected",
            hasPermission: true
        }));

        this.customTemplates.push(Object.assign(new CustomTemplate(), {
            templateName: this.customMenuIcon,
            columnName: "menuIconImage",
            hasPermission: true
        }));
    }

    getSelectedItemIds(): void {
        // this.selectedItemIds = this.dynamicGridComponent.items.filter(x => x["isSelected"] === true).map(x => x.id);
    }

    cellClick($event) {
    }

    getData($event): void {
        this.dataAsOf = $event.dataAsOf;
    }

    saveDynamicForm(): void {

    }

    disableSaveChangesButton(): boolean {
        //NOTE: this method has issues, it triggers the gRPC error and made the Catalog column blank, not sure why
        let disableSaveChanges = false;

        if (this.dynamicGridComponents !== undefined) {
            this.dynamicGridComponents.forEach(dynamicGridComponent => {
                if (dynamicGridComponent !== undefined) {
                    disableSaveChanges = (dynamicGridComponent.addedItems.length === 0 &&
                        dynamicGridComponent.updatedItems.length === 0 &&
                        dynamicGridComponent.deletedIds.length === 0);
                }
            });
        }

        return disableSaveChanges;
    }

    getAllDropDownDataForPageLevel(dataSourceNames: string[] = null) {
        let data = {};

        dataSourceNames.push("KeyValuePairGridComponents");
        dataSourceNames.push("KeyValuePairFormComponents");
        dataSourceNames.push("KeyValuePairActionButtonComponents");

        data['dataSourceNames'] = dataSourceNames;
        data['catalogId'] = this.catalogId;
        data['erpId'] = this.erpId;

        let request = new RetrieveDynamicDataRequest();
        request.dataSourceName = "GetAllDropDownData";
        request.data = data;

        this._dynamicDataServiceProxy.retrieveDynamicData(request)
            .pipe(
                finalize(() => {
                    this.gridIsLoading(false);
                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (result.data !== null) {
                    result.data.items.forEach(item => {
                        item = this.formatItemDateWithoutId(item);
                        this.setDataItems(item);
                    });
                }
            });
    }

    setDataItems(item: any): void {
        let dataSourceItemIndex = this.dataSourceItems.findIndex(x => x.dataSourceName === item.dataSourceName);

        // Updates the value of the data source item when a new item is retrieved.
        if (dataSourceItemIndex > -1 && item.dataSourceItems.length > 0) {
            this.dataSourceItems.splice(dataSourceItemIndex, 1);
        }

        this.dataSourceItems.push(item);
    }

    confirmPublishChanges() {
        this.message.confirm(
            this.l("AreYouSureYouWantToPublishChanges"),
            this.l("PublishChanges"),
            isConfirmed => {
                if (isConfirmed) {
                    this.publishChanges();
                } else {

                }
            },
        );
    }

    publishChanges(isFromDialog: boolean = false) {
        if (isFromDialog) {
            this.savingChangesFromDialog = true;
        } else {
            this.gridIsLoading();
            this.buttonDisabled = true;
        }

        let request = new SaveDynamicDataRequest();
        let data = {};
        let dynamicActionButton: DynamicActionButtonComponent = this.dynamicComponentComponents?.dynamicComponents?.find(x => x?.componentRef?.instance?.actionButtonStructure !== undefined)?.componentRef?.instance;

        if (dynamicActionButton !== undefined && dynamicActionButton.actionButtonStructure.componentSettings.buttonType === 'save') {
            dynamicActionButton?.dynamicSaveChangesButton.publishChanges(true);
        }
        else {
            //NOTE: loop multiple grid components
            if (this.dynamicGridComponents.length > 0) {
                let gridComponents: any[] = [];

                this.dynamicGridComponents.forEach(dynamicGridComponent => {
                    if (dynamicGridComponent !== undefined) {
                        let grid = {};

                        if (this.pageName === "DynamicPageMaintenance") {
                            dynamicGridComponent.gridDetailsDynamicGridComponents.forEach(gridDetailsDynamicGridComponent => {
                                if (gridDetailsDynamicGridComponent.gridName == "AttachDynamicForm") {
                                    grid['deletedIdsForAttachDynamicForm'] = gridDetailsDynamicGridComponent.deletedIds;
                                    grid['addedAndUpdatedItemsForAttachDynamicForm'] = gridDetailsDynamicGridComponent.items;
                                }
                                else if (gridDetailsDynamicGridComponent.gridName == "AttachDynamicGrid") {
                                    grid['deletedIdsForAttachDynamicGrid'] = gridDetailsDynamicGridComponent.deletedIds;
                                    grid['addedAndUpdatedItemsForAttachDynamicGrid'] = gridDetailsDynamicGridComponent.items;
                                }
                            });

                            data["selectedClientId"] = dynamicGridComponent.selectedClientId ?? 0;
                            data["selectedErpId"] = dynamicGridComponent.selectedErpId ?? 0;
                        }
                        else if (this.pageName === "DynamicFormMaintenance") {
                            dynamicGridComponent.gridDetailsDynamicGridComponents.forEach(gridDetailsDynamicGridComponent => {
                                if (gridDetailsDynamicGridComponent.gridName == "AttachDynamicForm") {
                                    grid['deletedIdsForFormPopUpAttachDynamicForm'] = gridDetailsDynamicGridComponent.deletedIds;
                                }
                                else if (gridDetailsDynamicGridComponent.gridName == "AttachDynamicGrid") {
                                    grid['deletedIdsForFormPopUpAttachDynamicGrid'] = gridDetailsDynamicGridComponent.deletedIds;
                                }
                            });
                        }
                        else if (this.pageName === "DynamicGridMaintenance") {
                            dynamicGridComponent.gridDetailsDynamicGridComponents.forEach(gridDetailsDynamicGridComponent => {
                                if (gridDetailsDynamicGridComponent.gridName == "AttachGridPopUpDynamicForm") {
                                    grid['deletedIdsForGridPopUpAttachDynamicForm'] = gridDetailsDynamicGridComponent.deletedIds;
                                }
                                else if (gridDetailsDynamicGridComponent.gridName == "AttachGridPopUpDynamicGrid") {
                                    grid['deletedIdsForGridPopUpAttachDynamicGrid'] = gridDetailsDynamicGridComponent.deletedIds;
                                }
                                else if (gridDetailsDynamicGridComponent.gridName == "AttachGridDetailsDynamicForm") {
                                    grid['deletedIdsForGridDetailsAttachDynamicForm'] = gridDetailsDynamicGridComponent.deletedIds;
                                }
                                else if (gridDetailsDynamicGridComponent.gridName == "AttachGridDetailsDynamicGrid") {
                                    grid['deletedIdsForGridDetailsAttachDynamicGrid'] = gridDetailsDynamicGridComponent.deletedIds;
                                }
                            });
                        }
                        else if (this.pageName === "DynamicPageDesignMaintenance") {
                            dynamicGridComponent.gridDetailsDynamicGridComponents.forEach(gridDetailsDynamicGridComponent => {
                                if (gridDetailsDynamicGridComponent.gridName === "AttachDetachDynamicActionButtonDynamicGrid") {
                                    grid['deletedIdsForAttachDetachDynamicActionButtonDynamicGrid'] = gridDetailsDynamicGridComponent.deletedIds;
                                    grid['addedAndUpdatedItemsForAttachDetachDynamicActionButtonDynamicGrid'] = gridDetailsDynamicGridComponent.items;
                                }
                                else if (gridDetailsDynamicGridComponent.gridName === "AttachDetachDynamicActionButtonDynamicForm") {
                                    grid['deletedIdsForAttachDetachDynamicActionButtonDynamicForm'] = gridDetailsDynamicGridComponent.deletedIds;
                                    grid['addedAndUpdatedItemsForAttachDetachDynamicActionButtonDynamicForm'] = gridDetailsDynamicGridComponent.items;
                                }
                            });
                        }
                        else if (this.pageName === "DynamicFormDesignMaintenance") {
                            dynamicGridComponent.gridDetailsDynamicGridComponents.forEach(gridDetailsDynamicGridComponent => {

                                if (gridDetailsDynamicGridComponent.gridName == "DynamicFieldMaintenance") {

                                    if (gridDetailsDynamicGridComponent.deletedIds.length > 0) {
                                        if (grid['deletedIdsForDynamicFields'] === undefined) {
                                            grid['deletedIdsForDynamicFields'] = gridDetailsDynamicGridComponent.deletedIds;
                                        }
                                        else {
                                            grid['deletedIdsForDynamicFields'].push(...gridDetailsDynamicGridComponent.deletedIds);
                                        }
                                    }

                                    const addedAndUpdatedItems = gridDetailsDynamicGridComponent.addedItems.concat(gridDetailsDynamicGridComponent.updatedItems);

                                    if (addedAndUpdatedItems.length > 0) {
                                        const uniqueItems = _uniqBy(addedAndUpdatedItems, 'parentId');

                                        uniqueItems.forEach(item => {
                                            const toBeAdded = dynamicGridComponent.items.filter(x => x.id === item["parentId"])[0];

                                            if (toBeAdded !== undefined) {
                                                const distinctItem = addedAndUpdatedItems.filter(x => x.id === toBeAdded.id).length === 0;
                                                const notYetAdded = dynamicGridComponent.updatedItems.filter(x => x.id === toBeAdded.id).length === 0;

                                                if (distinctItem && notYetAdded) {
                                                    dynamicGridComponent.updatedItems.push(toBeAdded);
                                                }
                                            }
                                        });
                                    }

                                }
                                else if (gridDetailsDynamicGridComponent.gridName === "DynamicFormDesignMaintenance") {
                                    // Process child grids
                                    gridDetailsDynamicGridComponent.gridDetailsDynamicGridComponents.forEach(childGridDetailsDynamicGridComponent => {

                                        if (childGridDetailsDynamicGridComponent.gridName === "DynamicFieldMaintenance") {

                                            // Process deleted IDs
                                            if (childGridDetailsDynamicGridComponent.deletedIds.length > 0) {
                                                grid['deletedIdsForDynamicFields'] = grid['deletedIdsForDynamicFields'] || [];
                                                grid['deletedIdsForDynamicFields'].push(...childGridDetailsDynamicGridComponent.deletedIds);
                                            }

                                            // Initialize or update addedAndUpdatedItems in grid
                                            grid['addedAndUpdatedItems'] = grid['addedAndUpdatedItems'] || [];
                                            grid['addedAndUpdatedItems'].push(...childGridDetailsDynamicGridComponent.addedItems);
                                            grid['addedAndUpdatedItems'].push(...childGridDetailsDynamicGridComponent.updatedItems);

                                            // Update the main dynamic grid component based on child grid changes
                                            if (grid['addedAndUpdatedItems'].length > 0) {
                                                const uniqueItems = _uniqBy(grid['addedAndUpdatedItems'], 'parentId');

                                                uniqueItems.forEach(item => {
                                                    dynamicGridComponent.items.forEach(toBeAdded => {
                                                        toBeAdded.childGroupFieldSets.forEach(y => {
                                                            if (y.id === item["parentId"]) {
                                                                const distinctItem = grid['addedAndUpdatedItems'].some(z => z.id === toBeAdded.id);
                                                                const notYetAdded = !dynamicGridComponent.updatedItems.some(a => a.id === toBeAdded.id);

                                                                if (distinctItem && notYetAdded) {
                                                                    dynamicGridComponent.updatedItems.push(toBeAdded);
                                                                }
                                                            }
                                                        });
                                                    });
                                                });
                                            }
                                        }
                                    });

                                    // Process deleted IDs for ChildGroupFieldSets
                                    if (gridDetailsDynamicGridComponent.deletedIds.length > 0) {
                                        grid['deletedIdsForChildGroupFieldSets'] = grid['deletedIdsForChildGroupFieldSets'] || [];
                                        grid['deletedIdsForChildGroupFieldSets'].push(...gridDetailsDynamicGridComponent.deletedIds);
                                    }

                                    // Combine added and updated items
                                    let addedAndUpdatedItems = gridDetailsDynamicGridComponent.addedItems.concat(gridDetailsDynamicGridComponent.updatedItems);

                                    // Update dynamic grid component based on top-level grid changes
                                    if (addedAndUpdatedItems.length > 0) {
                                        const uniqueItems = _uniqBy(addedAndUpdatedItems, 'parentId');

                                        uniqueItems.forEach(item => {
                                            const toBeAdded = dynamicGridComponent.items.find(x => x.id === item["parentId"]);

                                            if (toBeAdded) {
                                                const distinctItem = addedAndUpdatedItems.some(x => x.id === toBeAdded.id);
                                                const notYetAdded = !dynamicGridComponent.updatedItems.some(a => a.id === toBeAdded.id);

                                                if (distinctItem && notYetAdded) {
                                                    dynamicGridComponent.updatedItems.push(toBeAdded);
                                                }
                                            }
                                        });
                                    }
                                }

                            });
                        }

                        //NOTE: Assign parentId
                        if (this.retrieveDataBy !== undefined) {
                            if (dynamicGridComponent.items !== undefined) {
                                dynamicGridComponent.items.map(x => {
                                    x.parentId = this.retrieveDataBy;
                                });
                            }
                        }

                        //TODO: Add support for multiple grid returnedData
                        grid['gridName'] = dynamicGridComponent.gridStructureDataSourceName;
                        grid['gridDataDataSourceName'] = dynamicGridComponent.gridDataDataSourceName;

                        grid['items'] = dynamicGridComponent.items;
                        grid['deletedIds'] = dynamicGridComponent.deletedIds;

                        if (this.pageName !== "DynamicNavigationMaintenance" && dynamicGridComponent.deletedIds.length === 0) {
                            grid['addedAndUpdatedItems'] = dynamicGridComponent.items;
                            this.updateDisplayOrder(grid['addedAndUpdatedItems'].sort(function (a, b) { return a.displayOrder - b.displayOrder }));
                        }
                        else {
                            grid['addedAndUpdatedItems'] = dynamicGridComponent.items;
                            this.updateDisplayOrder(grid['addedAndUpdatedItems'].sort(function (a, b) { return a.displayOrder - b.displayOrder }));
                        }

                        // support for Attach/Detach Dynamic Tabs' unique implementation of display order
                        if (this.pageName === "DynamicPageDesignMaintenance" && dynamicGridComponent.gridStructureDataSourceName === "GridStructureAttachDetachDynamicTabComponent") {
                            this.updateDisplayOrder(grid['items'].sort(function (a, b) { return a.displayOrder - b.displayOrder }));
                        }

                        gridComponents.push(grid);
                    }
                });

                data['gridComponents'] = gridComponents;
            }

            //NOTE: loop multiple form components
            if (this.dynamicFormComponents.length > 0) {
                let formComponents: any[] = [];

                this.dynamicFormComponents.forEach(dynamicFormComponent => {
                    if (dynamicFormComponent !== undefined) {
                        let form = {};

                        form['parentId'] = this.retrieveDataBy;
                        form['formName'] = dynamicFormComponent.formStructureDataSourceName;
                        form['value'] = dynamicFormComponent.dynamicForm?.value;

                        formComponents.push(form);
                    }
                });

                data['formComponents'] = formComponents;
            }

            data['parentId'] = this.retrieveDataBy;

            request.action = "save";
            request.dataSourceName = this.pageName;
            request.data = data;

            // NOTE: For Dynamic Maintenance Pages. Dynamic Pages is in dynamic-save-changes-button.component.ts, line 258.
            // console.log("request");
            // console.log(request);
            // this.buttonDisabled = false;
            // this.gridIsLoading(false);
            // return;

            this._dynamicDataServiceProxy.saveDynamicData(request)
                .pipe(
                    catchError(() => {
                        return of(undefined);
                    }),
                    finalize(() => {
                        if (isFromDialog) {
                            this.savingChangesFromDialog = true;
                        } else {
                            this.buttonDisabled = false;
                            this.gridIsLoading(false);
                        }

                        this.cd.markForCheck();
                    })
                ).subscribe(result => {
                    if (result !== null || result !== undefined) {
                        if (result.data && Object.keys(result.data).length === 0 && Object.getPrototypeOf(result.data) === Object.prototype) {
                            this.notify.success(this.l("ChangesSuccessfullyPublished"));
                        }
                    }

                    if (result.data.isSuccess) {
                        this.dynamicGridComponents.forEach(dynamicGridComponent => {
                            if (dynamicGridComponent !== undefined) {
                                if (result.data.returnedData.length > 0) {
                                    const filteredItems = dynamicGridComponent.items.filter(x => x.id !== "");
                                    dynamicGridComponent.items = filteredItems;

                                    if (this.pageName === "DynamicFormDesignMaintenance") {

                                        result.data.returnedData.forEach(item => {
                                            item = this.formatItemDateWithoutId(item);

                                            if (item.dynamicFields !== undefined) {
                                                item.dynamicFields.forEach(item => {
                                                    item = this.formatItemDateWithoutId(item);
                                                });
                                            }

                                            if (item.childGroupFieldSets !== undefined) {
                                                item.childGroupFieldSets.forEach(item => {
                                                    item = this.formatItemDateWithoutId(item);

                                                    if (item.dynamicFields !== undefined) {
                                                        item.dynamicFields.forEach(item => {
                                                            item = this.formatItemDateWithoutId(item);
                                                        });
                                                    }
                                                });
                                            }
                                        });

                                        dynamicGridComponent.items = result.data.returnedData;
                                        dynamicGridComponent.addedItems = [];
                                    }
                                    else if (this.pageName === "DynamicNavigationMaintenance") {
                                        result.data.returnedData.forEach(returnedItem => {
                                            returnedItem = this.formatItemDateWithoutId(returnedItem);
                                            this.updateChildDateFieldsforMenu(returnedItem.gridPopUpAttachedDynamicNavigationComponents);
                                        });

                                        dynamicGridComponent.items = result.data.returnedData;

                                        abp.event.trigger('app.updateMainMenu');
                                    }
                                    else {
                                        result.data.returnedData.forEach(returnedItem => {
                                            for (var key in returnedItem) {
                                                if (key.toLowerCase().includes("date") == true && key.includes("Id") == false) {
                                                    returnedItem[key] = returnedItem[key] ? DateTime.fromISO(returnedItem[key]) : <any>undefined;
                                                }
                                            }

                                            //NOTE: Support for concurrency. Replace item from dynamicGridComponent.items if the item is there
                                            const itemsIndex = dynamicGridComponent.items.findIndex(x => x.id === returnedItem.id);
                                            const itemExists = itemsIndex !== -1;
                                            if (itemExists) {
                                                dynamicGridComponent.items.splice(itemsIndex, 1);
                                            }
                                            dynamicGridComponent.items.unshift(returnedItem);

                                            dynamicGridComponent.addedItems = [];
                                        });
                                    }
                                }

                                if (this.pageName === "DynamicPageDesignMaintenance") {
                                    dynamicGridComponent.addedItems = [];
                                }

                                dynamicGridComponent.updatedItems = [];
                                dynamicGridComponent.deletedIds = [];

                                dynamicGridComponent.populateGrid();
                            }
                        });

                        // Clear the Dynamic Page Layout to make sure that any cached pages that use the grid/form/page/action button is updated
                        if (this.pageName === "DynamicGridMaintenance" || this.pageName === "DynamicGridDesignMaintenance" ||
                            this.pageName === "DynamicFormMaintenance" || this.pageName === "DynamicFormDesignMaintenance" ||
                            this.pageName === "DynamicPageMaintenance" || this.pageName === "DynamicPageDesignMaintenance" ||
                            this.pageName === "DynamicActionButtonMaintenance") {
                            this.itemsStateService.dynamicPageLayout = {};
                        }

                        if (result.data.validationMessage.length > 0) {
                            result.data.validationMessage.forEach(message => {
                                this.notify.warn(this.l(message));
                            });
                        }
                        else {
                            this.dynamicGridComponents.forEach(x => x.disableActionButton = true);
                            this.dynamicFormComponents.forEach(x => {
                                if (x !== undefined) {
                                    x.dynamicForm?.markAsPristine();
                                }
                            });
                            this.notify.success(this.l("ChangesSuccessfullyPublished"));
                        }
                    }

                    if (isFromDialog) {
                        this.selectedResponseSubject.next(true);
                        this.openUnsavedChanges = false;
                    }
                });
        }
    }

    updateChildDateFieldsforMenu(list: any[]) {
        for (var item of list) {
            item = this.formatItemDateWithoutId(item);
            if (item.gridPopUpAttachedDynamicNavigationComponents !== undefined && item.gridPopUpAttachedDynamicNavigationComponents.length > 0) {
                this.updateChildDateFieldsforMenu(item.gridPopUpAttachedDynamicNavigationComponents);
            }
        }
    }

    updateDisplayOrder(list) {
        let counter = 1;
        if (this.pageName === 'DynamicNavigationMaintenance') {
            for (var item of list) {
                item.displayOrder = counter;
                if (item.gridPopUpAttachedDynamicNavigationComponents !== undefined && item.gridPopUpAttachedDynamicNavigationComponents.length > 0) {
                    this.updateDisplayOrder(item.gridPopUpAttachedDynamicNavigationComponents);
                }

                counter++;
            }
        }
        else if (this.pageName === 'DynamicPageDesignMaintenance') {
            for (var item of list) {
                item.displayOrder = counter;

                counter++;
            }
        }
        else if (this.pageName === 'DynamicGridDesignMaintenance') {
            for (var item of list) {
                item.displayOrder = counter;

                counter++;
            }
        }
        else if (this.pageName === 'DynamicFormDesignMaintenance') {
            for (var item of list) {
                item.displayOrder = counter;
                if (item.dynamicFields !== undefined && item.dynamicFields.length > 0) {
                    this.updateDisplayOrder(item.dynamicFields.sort(function (a, b) { return a.displayOrder - b.displayOrder }));
                }

                if (item.childGroupFieldSets !== undefined && item.childGroupFieldSets.length > 0) {
                    this.updateDisplayOrder(item.childGroupFieldSets.sort(function (a, b) { return a.displayOrder - b.displayOrder }));
                }

                counter++;
            }
        }
    }

    goToPageLayoutDefinitions(): void {
        this._router.onSameUrlNavigation = 'ignore';
        this._router.navigate(['app/main/page-layout-definitions/' + this.parentPageName, this.parentPageId]);
    }

    goToPage(): void {
        this._router.onSameUrlNavigation = 'ignore';
        this._router.navigate(['app/main/dynamic-page/' + this.parentPageId]);
    }

    checkGridAndForms() {
        let validGrids = this.dynamicGridComponents?.find(x => x.disableActionButton === false);
        let validForms = this.dynamicFormComponents?.find(x => x.dynamicForm?.valid === true && x.dynamicForm?.dirty === true);
        let validComponents = undefined;
        this.disableSave = false;

        let dynamicActionButton: DynamicActionButtonComponent = this.dynamicComponentComponents?.dynamicComponents?.find(x => x?.componentRef?.instance?.actionButtonStructure !== undefined)?.componentRef?.instance;

        if (dynamicActionButton !== undefined && ['save', 'saveredirect'].includes(dynamicActionButton.actionButtonStructure.componentSettings.buttonType)) {
            this.dynamicComponentComponents?.dynamicComponents?.forEach(x => {
                let dynamicComponent = x.componentRef.instance;
                if (dynamicComponent.getComponentName() === "DynamicGridComponent") {
                    let dynamicGridComponent: DynamicGridComponent = dynamicComponent;
                    if (dynamicGridComponent.disableActionButton === false) {
                        validComponents = false;
                    }
                }
                else if (dynamicComponent.getComponentName() === "DynamicFormComponent") {
                    let dynamicFormComponent: DynamicFormComponent = dynamicComponent;
                    if (dynamicFormComponent.dynamicForm?.dirty === true) {
                        validComponents = false;
                    }

                    if (dynamicFormComponent.dynamicForm?.invalid === true) {
                        this.disableSave = true;
                    }
                }
            });
        }

        if (validGrids !== undefined || validForms !== undefined || validComponents !== undefined) { // There are changes in the grid or in the form
            return false;
        }
        else {
            return true;
        }
    }

    @HostListener('window:beforeunload')
    canDeactivate(withinThePage: boolean): Observable<boolean> | boolean {
        return this.verifyUnsavedChanges(withinThePage);
    }

    verifyUnsavedChanges(withinThePage: boolean) {
        const isActionReloadOrClose = (withinThePage === undefined);

        if (isActionReloadOrClose) {
            if (!this.checkGridAndForms()) {
                this.cd.markForCheck();
                return window.confirm(this.l("ChangesYouMadeMayNotBeSaved"));
            }
        } else {
            if (!this.checkGridAndForms()) {
                this.cd.markForCheck();
                this.openUnsavedChanges = true;
                this.gridIsLoading(false);

                return this.selectedResponseAction$;
            } else {
                return true;
            }
        }
    }

    setDynamicPageLabelKey(pageLabelKey: string): void {
        this.pageLabelKey = pageLabelKey;
    }

    isNotInAppMain() {
        return this._activatedRoute.snapshot["_urlSegment"].segments[0].path !== 'app' && this._activatedRoute.snapshot["_urlSegment"].segments[1].path !== 'main';
    }

    goToMasterPage(masterPageId: string): void {
        if (this._activatedRoute.snapshot["_urlSegment"].segments[0].path !== 'app' && this._activatedRoute.snapshot["_urlSegment"].segments[1].path !== 'main') {
            this._router.navigate(['dynamic-page/' + masterPageId]);
        }
        else {
            this._router.navigate(['app/main/dynamic-page/' + masterPageId]);
        }
    }

    goToDynamicFrameworkMasterPage(masterPageLabelKey: string): void {
        const masterPageName = this._activatedRoute.snapshot["_urlSegment"].segments[3]?.path;
        const masterPageLabelKey2 = this._activatedRoute.snapshot["_urlSegment"].segments[5]?.path;
        const masterDetailPageId = this._activatedRoute.snapshot["_urlSegment"].segments[6]?.path;

        if ((masterPageName === "dynamic-grid-design-maintenance" || masterPageName === "dynamic-form-design-maintenance") && masterPageLabelKey2 === "DynamicPageDesignMaintenance") {
            this._router.navigate(['app/main/admin-page/dynamic-page-design-maintenance/' + masterDetailPageId + '/DynamicPageMaintenance']);
        }
        else {
            this._router.navigate(['app/main/admin-page/' + this.convertPascalCaseToKebabCase(masterPageLabelKey)]);
        }
    }

    retrieveRequiredData() {
        if (this.pageName === "DynamicPageDesignMaintenance" || this.pageName === "DynamicNavigationMaintenance" ||
            this.pageName === "AttachGridPopUpDynamicNavigation" || this.pageName === "DynamicActionButtonMaintenance") {
            this.getAllDropDownDataForMaintenancePages();
        }
    }

    getAllDropDownDataForMaintenancePages() {
        let data = {};

        data['catalogId'] = this.catalogId;
        data['erpId'] = this.erpId;
        data['clientId'] = this.clientId;

        let request = new RetrieveDynamicDataRequest();
        request.dataSourceName = "GetAllDropDownDataForMaintenancePages";
        request.data = data;

        this._dynamicDataServiceProxy.retrieveDynamicData(request)
            .pipe(
                finalize(() => {
                    this.gridIsLoading(false);
                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (result.data !== null) {
                    result.data.items.forEach(item => {
                        item = this.formatItemDateWithoutId(item);
                        this.setDataItems(item);
                    });
                }
            });
    }

    closeUnsavedChangesDialog(): void {
        this.selectedResponseSubject.next(false);

        this.openUnsavedChanges = false;
    }

    discardChanges(): void {
        this.selectedResponseSubject.next(true);

        this.openUnsavedChanges = false;
    }

    subUnsavedChangesUpdate() {
        abp.event.on('app.clearUnsavedChanges', this.clearUnsavedChanges);
    }

    unSubUnsavedChangesUpdate() {
        abp.event.off('app.clearUnsavedChanges', this.clearUnsavedChanges);
    }

    clearUnsavedChanges = () => {
        this.selectedResponseSubject.next(true);
        this.openUnsavedChanges = false;
    }
}
